import React, { useRef, useState, useContext } from "react"
import { Form, Button, Card, Alert, Container } from "react-bootstrap"
import { loggedUser,resetPassword} from  "../components/FirebaseAuth"
import { Link } from "gatsby"
import { PageLayout } from "../components"
import { SEO } from "../utils"
import ThemeContext from "../utils/theme"

export default function ForgotPassword() 
{
  const emailRef = useRef()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const { dark } = useContext(ThemeContext)

  async function callReset()
  {
    await resetPassword(emailRef.current.value).then((resp) => 
    {
      setMessage("Check your inbox for further instructions")

    }).catch((error) => 
    {
      setError(String(error) )
    })
  }

  function handleSubmit(e) 
  {
    e.preventDefault()
    try 
    {
      setMessage("")
      setError("")
      setLoading(true)
      callReset()
    } 
    catch 
    {
      setError("Failed to reset password")
    }
    setLoading(false)
  }

  return (
    <>
      <PageLayout>
        <SEO title="Forget Password" />
        <Container className="d-flex align-items-center justify-content-center" >
          <div className="w-100 text-color" style={{ maxWidth: "400px" }}>
            <Card style={{ borderColor:`${dark ? '#333' : '#ddd'}`,borderWidth:"3px" }}>
              <Card.Body>

                <h2 className="text-center mb-4">Password Reset</h2>
                {error && <Alert style={{fontWeight:"900",backgroundColor:"#d9534f",color:"white",borderColor:"#d9534f"}}>{error}</Alert>}
                {message && <Alert style={{fontWeight:"900",backgroundColor:"#5cb85c",color:"white",borderColor:"#5cb85c"}}>{message}</Alert>}

                <Form onSubmit={handleSubmit}>
                  <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" ref={emailRef} required />
                  </Form.Group>
                  <br/>
                  <Button disabled={loading} type="submit">
                    Reset Password
                  </Button>
                </Form>
                <div className="w-100 text-center mt-3">
                  <Link to="/Login">Login</Link>
                </div>
                
              </Card.Body>
            </Card>

            <div className="w-100 text-center mt-2">
              Need an account? <Link to="/Signup">Sign Up</Link>
            </div>
            <br/><br/>
          </div>
        </Container>
      </PageLayout>
    </>
  )
}